<template>
  <div>
    <div class="mb-2">
      <a-collapse accordion v-if="selectedMapel !== 'All'">
        <a-collapse-panel key="1" header="Description">
          <div class="text-center mt-4"><b>NOTES: </b></div>
          <div
            class="d-md-flex justify-content-md-center justify-content-lg-left"
          >
            <div
              class="mx-2 mb-3"
              v-for="(note, i) in notes[selectedReportText]"
              :key="i"
            >
              {{ note.code }}: {{ note.full }}
            </div>
            <div class="mx-2 mb-3">Avg: Average</div>
          </div>
        </a-collapse-panel>
      </a-collapse>
      <a-collapse accordion v-else>
        <a-collapse-panel
          v-if="reportType === 'mid'"
          key="1"
          header="Description"
        >
          <div class="d-flex justify-content-flex-start align-items-center">
            <span>
              <ul>
                <h4>Detail Mata Pelajaran</h4>
                <li>P1-P4 = Nilai Pengetahuan / Keterampilan di KD1-KD4</li>
                <li>Knowledge = Nilai CBT PTS Pengetahuan</li>
                <li>Skill = Nilai PTS Keterampilan</li>
              </ul>
            </span>
            <span class="pt-4">
              <ul>
                <li>Jumlah = Total nilai dari semua mata pelajaran</li>
                <li>Rata2 = Rata - rata nilai dari semua mata pelajaran</li>
                <li>
                  Rank = Ranking siswa / siswi di kelas berdasarkan jumlah nilai
                  yang diperoleh
                </li>
              </ul>
            </span>
            <span>
              <ul>
                <h4>Absensi Siswa</h4>
                <li>S = Sakit</li>
                <li>I = Izin</li>
                <li>A = Alfa</li>
              </ul>
            </span>
          </div>
        </a-collapse-panel>
        <a-collapse-panel v-else key="2" header="Description">
          <div class="d-flex justify-content-around align-items-center">
            <ul>
              <h4>Singkatan Pada Kolom Mata Pelajaran</h4>
              <li>K = Nilai Pengetahuan</li>
              <li>S = Nilai Keterampilan</li>
            </ul>
            <ul>
              <h4>Knowledge = Nilai Pengetahuan</h4>
              <li>Rumus = ((2*Rata-rata Harian)+PTS+(PAS atau PAT))/4</li>
            </ul>
            <ul>
              <h4>Skill = Nilai Keterampilan</h4>
              <li>Rumus = (Rata-rata Harian+PTS+(PAS atau PAT))/3</li>
            </ul>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
    <a-table
      :columns="columnsTable"
      :data-source="dataTable"
      :scroll="{ x: 1050, y: 500 }"
      :pagination="{ pageSize: 30, hideOnSinglePage: true }"
      :loading="loadingTable"
      size="small"
      class="table-homeroom"
      bordered
    >
      <template slot="title">
        <div class="d-flex align-items-center py-2">
          <a-radio-group
            @change="handleReportType"
            v-model="reportType"
            button-style="solid"
            size="large"
          >
            <a-radio-button value="mid">Mid Report</a-radio-button>
            <a-radio-button value="final">Final Report</a-radio-button>
          </a-radio-group>
          <div class="ml-auto" v-if="!isCurriculum">
            <a-button
              v-if="dataTable.length"
              @click.prevent="handleExport"
              :loading="loadingExport"
              class="btn btn-outline-danger rounded mr-3"
              size="large"
              icon="file-pdf"
              type="default"
              >{{
                loadingExport
                  ? 'Exporting...'
                  : `Export ${
                      reportType === 'mid' ? 'Mid' : 'Final'
                    } Ledger Report`
              }}</a-button
            >
            <a-select
              size="large"
              ref="SelectSubject"
              placeholder="Select Subject"
              style="width: 200px"
              class="mr-3"
              v-model="selectedMapel"
              @change="handleSelectMapel"
            >
              <a-select-option
                v-for="item in subjects"
                :key="item.id_mata_pelajaran"
                :value="item.id_mata_pelajaran"
                >{{ item.mata_pelajaran.nama }}</a-select-option
              >
            </a-select>
            <a-select
              v-if="selectedMapel !== 'All' && !kumer"
              size="large"
              style="width: 150px"
              v-model="selectedReportText"
              @change="handleSelectReportText"
            >
              <a-select-option value="knowledge">Knowledge</a-select-option>
              <a-select-option value="skill">Skill</a-select-option>
            </a-select>
          </div>
        </div>
      </template>
      <span slot="nilai" slot-scope="text">
        <a-tag v-if="!text.data">
          <a-icon type="minus" />
        </a-tag>
        <a-tag :color="color(Number(text.data))" v-else>
          {{ text.data.toFixed(1) }}
        </a-tag>
      </span>
      <div
        slot="nilaiKd"
        slot-scope="text"
        class="d-flex align-items-center justify-content-center"
      >
        <!-- <span v-if="!isEdit" slot="nilaiKd" slot-scope="text"> -->
        <a-tag v-if="!text || text.data === null">
          <a-icon type="minus" />
        </a-tag>
        <a-tag :color="color(Number(text.data))" v-else>
          {{ text.data }}
        </a-tag>
      </div>
      <div
        slot="assignment"
        slot-scope="text"
        class="d-flex align-items-center justify-content-center"
      >
        <!-- <span v-if="!isEdit" slot="nilaiKd" slot-scope="text"> -->
        <a-tag v-if="!text || text.data === null">
          <a-icon type="minus" />
        </a-tag>
        <a-tag :color="color(Number(text.data))" v-else>
          {{ text.data }}
        </a-tag>
      </div>
      <div
        slot="oral"
        slot-scope="text"
        class="d-flex align-items-center justify-content-center"
      >
        <!-- <span v-if="!isEdit" slot="nilaiKd" slot-scope="text"> -->
        <a-tag v-if="!text || text.data === null">
          <a-icon type="minus" />
        </a-tag>
        <a-tag :color="color(Number(text.data))" v-else>
          {{ text.data }}
        </a-tag>
      </div>
      <div slot="written" slot-scope="text">
        <!-- <span v-if="!isEdit" slot="nilaiKd" slot-scope="text"> -->
        <a-tag v-if="!text || text.data === null">
          <a-icon type="minus" />
        </a-tag>
        <a-tag :color="color(Number(text.data))" v-else>
          {{ text.data }}
        </a-tag>
      </div>
      <div slot="nilaiUjian" slot-scope="text">
        <a-tag v-if="!text || text.data === null">
          <a-icon type="minus" />
          <!-- {{text}} -->
        </a-tag>
        <a-tag :color="color(Number(text.data))" v-else>
          {{ text.data }}
        </a-tag>
      </div>
      <span slot="kdAvg" slot-scope="text">
        <a-tag v-if="!text || text.data === null">
          <a-icon type="minus" />
        </a-tag>
        <a-tag :color="color(Number(text))" v-else>
          {{ text.toFixed(1) }}
        </a-tag>
      </span>
    </a-table>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import { LedgerMid, LedgerFinal } from '@/helpers/ledgerExcel'
const columnsGradeMid = [
  {
    title: 'NO',
    dataIndex: 'no',
    key: 'no',
    width: 100,
    align: 'center',
    fixed: 'left',
    sorter: (a, b) => a.no - b.no,
  },
  {
    title: 'Nama Siswa',
    dataIndex: 'namaSiswa',
    key: 'namaSiswa',
    fixed: 'left',
    width: 200,
    scopedSlots: { customRender: 'namaSiswa' },
  },
  {
    title: 'NIS',
    dataIndex: 'nis',
    key: 'nis',
    width: 120,
    align: 'center',
    scopedSlots: { customRender: 'nis' },
  },
  {
    title: 'NISN',
    dataIndex: 'nisn',
    key: 'nisn',
    width: 120,
    align: 'center',
    scopedSlots: { customRender: 'nisn' },
  },
  {
    title: 'Wali Kelas',
    dataIndex: 'waliKelas',
    key: 'waliKelas',
    width: 200,
    align: 'center',
    scopedSlots: { customRender: 'waliKelas' },
  },
  {
    title: 'Kelas',
    dataIndex: 'kelas',
    key: 'kelas',
    width: 150,
    align: 'center',
    scopedSlots: { customRender: 'kelas' },
  },
  {
    title: 'Rekapitulasi Raport Per Mata Pelajaran',
    align: 'center',
    key: 'rekapRaport',
    width: 1000,
    // children: '',
  },
  {
    title: 'Jumlah',
    dataIndex: 'jumlahNilai',
    key: 'jumlahNilai',
    width: 200,
    align: 'center',
    scopedSlots: { customRender: 'jumlahNilai' },
  },
  {
    title: 'Rata2',
    dataIndex: 'rata2Nilai',
    key: 'rata2Nilai',
    width: 200,
    align: 'center',
    scopedSlots: { customRender: 'rata2Nilai' },
  },
  {
    title: 'Rank',
    dataIndex: 'rank',
    key: 'rank',
    width: 100,
    align: 'center',
    sorter: (a, b) => a.rank - b.rank,
  },
  {
    title: 'Absensi Siswa',
    children: [
      {
        title: 'S',
        dataIndex: 'sakit',
        key: 'sakit',
        width: 80,
        align: 'center',
        scopedSlots: { customRender: 'sakit' },
      },
      {
        title: 'I',
        dataIndex: 'izin',
        key: 'izin',
        width: 80,
        align: 'center',
        scopedSlots: { customRender: 'izin' },
      },
      {
        title: 'A',
        dataIndex: 'alfa',
        key: 'alfa',
        width: 80,
        align: 'center',
        scopedSlots: { customRender: 'alfa' },
      },
    ],
  },
]

const columnsGradeFinal = [
  {
    title: 'NO',
    dataIndex: 'no',
    key: 'no',
    width: 100,
    align: 'center',
    fixed: 'left',
    sorter: (a, b) => a.no - b.no,
  },
  {
    title: 'Nama Siswa',
    dataIndex: 'namaSiswa',
    key: 'namaSiswa',
    fixed: 'left',
    width: 200,
    align: 'center',
    scopedSlots: { customRender: 'namaSiswa' },
  },
  {
    title: 'NIS',
    dataIndex: 'nis',
    key: 'nis',
    width: 120,
    align: 'center',
    scopedSlots: { customRender: 'nis' },
  },
  {
    title: 'NISN',
    dataIndex: 'nisn',
    key: 'nisn',
    width: 120,
    align: 'center',
    scopedSlots: { customRender: 'nisn' },
  },
  {
    title: 'Jenis Kelamin',
    dataIndex: 'jenisKelamin',
    key: 'jenisKelamin',
    width: 150,
    align: 'center',
    scopedSlots: { customRender: 'jenisKelamin' },
  },
  {
    title: 'Rekapitulasi Raport Per Mata Pelajaran',
    align: 'center',
    key: 'rekapRaport',
    children: '',
  },
  {
    title: 'Jumlah',
    dataIndex: 'jumlahNilai',
    key: 'jumlahNilai',
    width: 200,
    align: 'center',
    scopedSlots: { customRender: 'jumlahNilai' },
  },
  {
    title: 'Rata2',
    dataIndex: 'rata2Nilai',
    key: 'rata2Nilai',
    width: 200,
    align: 'center',
    scopedSlots: { customRender: 'rata2Nilai' },
  },
  {
    title: 'Rank',
    dataIndex: 'rank',
    key: 'rank',
    width: 100,
    align: 'center',
    sorter: (a, b) => a.rank - b.rank,
  },
]

const rawColumns = [
  {
    title: 'No',
    // width: '5%',
    width: 80,
    dataIndex: 'no',
    key: 'no',
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'Name',
    // width: '100%',
    width: 370,
    dataIndex: 'nama',
    key: 'nama',
    fixed: 'left',
    ellipsis: true,
    // scopedSlots: { customRender: 'Name' },
  },
  {
    title: 'KD Avg',
    dataIndex: 'kdAvg',
    key: 'kdAvg',
    width: 80,
    align: 'center',
    scopedSlots: { customRender: 'nilai' },
  },
  {
    title: 'PTS',
    dataIndex: 'pts',
    key: 'pts',
    width: 100,
    align: 'center',
    // slots: { title: 'titlePts' },
    // fixed: 'right',
    scopedSlots: { customRender: 'nilaiUjian' },
  },
]

const rawKumerColumns = [
  {
    title: 'No',
    // width: '5%',
    width: 80,
    dataIndex: 'no',
    key: 'no',
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'Name',
    // width: '100%',
    width: 370,
    dataIndex: 'nama',
    key: 'nama',
    fixed: 'left',
    ellipsis: true,
    // scopedSlots: { customRender: 'Name' },
  },
]
const knowledgeChildrenKDCol = [
  {
    title: 'A',
    dataIndex: 'assignment',
    width: 120,
    className: 'startFocus',
    scopedSlots: { customRender: 'assignment' },
    // scopedSlots: { customRender: 'nilaiKd' },
  },
  {
    title: 'W',
    dataIndex: 'written',
    width: 120,
    scopedSlots: { customRender: 'written' },
    // scopedSlots: { customRender: 'nilaiKd' },
    // scopedSlots: { customRender: 'nilaiUjian' },
  },
  {
    title: 'O',
    dataIndex: 'oral',
    width: 120,
    scopedSlots: { customRender: 'oral' },
    // scopedSlots: { customRender: 'nilaiKd' },
  },
]

const skillChildrenKDCol = [
  {
    title: 'Pra',
    dataIndex: 'practical',
    width: 80,
    className: 'startFocus',
    // scopedSlots: { customRender: 'practical' },
    scopedSlots: { customRender: 'nilaiKd' },
  },
  {
    title: 'Pro',
    dataIndex: 'project',
    width: 80,
    // scopedSlots: { customRender: 'project' },
    scopedSlots: { customRender: 'nilaiKd' },
  },
  {
    title: 'Oth',
    dataIndex: 'others',
    width: 80,
    // scopedSlots: { customRender: 'others' },
    scopedSlots: { customRender: 'nilaiKd' },
  },
]

const notes = {
  skill: [
    {
      code: 'Pra',
      full: 'Practical',
    },
    {
      code: 'Pro',
      full: 'Project',
    },
    {
      code: 'Oth',
      full: 'Others',
    },
    {
      code: 'NK',
      full: 'Skill Grade (Nilai Keterampilan)',
    },
  ],
  knowledge: [
    {
      code: 'W',
      full: 'Written',
    },
    {
      code: 'A',
      full: 'Assignments',
    },
    {
      code: 'O',
      full: 'Oral',
    },
    {
      code: 'NP',
      full: 'Knowledge Grade (Nilai Pengetahuan)',
    },
  ],
}
const kumer = false

export default {
  props: {
    subjects: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      notes,
      columnsGradeMid,
      columnsGradeFinal,
      skillChildrenKDCol,
      knowledgeChildrenKDCol,
      rawColumns,
      rawKumerColumns,
      columns: [],
      dataTable: [],
      listKd: [],
      loadingTable: false,
      loadingExport: false,
      selectedMapel: 'All',
      reportType: 'mid',
      selectedReportText: 'knowledge',
      idWaliKelas: null,
      isCurriculum: false,
      kumer,
    }
  },
  mounted() {
    if (this.$route.query.isCurriculum) {
      this.isCurriculum = true
      this.idWaliKelas = this.$route.query.id_walikelas
    } else {
      this.idWaliKelas = this.user.id
    }
    this.fetchDataTableLedger()
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    columnsTable() {
      let columns = []
      if (this.selectedMapel === 'All') {
        if (this.reportType === 'mid') {
          columns = this.columnsGradeMid
        } else {
          columns = this.columnsGradeFinal
        }
      } else {
        const kdCol = {
          title: 'KD-',
          code: 'KD',
          width: 320,
          children: [],
        }
        const indexNameCol = this.kumer
          ? this.rawKumerColumns.map((col) => col.key).indexOf('nama')
          : this.rawColumns.map((col) => col.key).indexOf('nama')
        const rawLength = this.kumer
          ? this.rawKumerColumns.length
          : this.rawColumns.length

        for (let i = 0; i < rawLength; i++) {
          let childrens = []
          const col = this.rawColumns[i]
          if (this.kumer) {
            const formatidChildrenCol = []
            let temp = 3
            if (this.maxLengthFormatifColumn > 3) {
              temp = this.maxLengthFormatifColumn
            }
            for (let i = 0; i < temp; i++) {
              formatidChildrenCol.push({
                title: 'TP-' + (i + 1),
                dataIndex: 'formatif',
                width: 120,
                scopedSlots: { customRender: 'assignment' },
              })
            }
            childrens = formatidChildrenCol
          } else {
            childrens =
              this.selectedReportText === 'knowledge'
                ? this.knowledgeChildrenKDCol
                : this.selectedReportText === 'skill'
                ? this.skillChildrenKDCol
                : []
          }
          if (['no', 'nama'].includes(col.key) && this.listKd.length < 2) {
            columns.push({
              ...col,
              fixed: null,
            })
          } else {
            if (col.key === 'pts') {
              columns.push({
                ...col,
                dataIndex: `${col.dataIndex}_${this.selectedReportText}`,
                key: `${col.key}_${this.selectedReportText}`,
              })
            } else columns.push(col)
          }

          if (i === indexNameCol) {
            if (this.listKd.length) {
              const colKds = this.listKd.map((kd) => {
                const children = childrens.map((child, idx) => {
                  // const scopedSlots = child.dataIndex === 'written' ? {} : { scopedSlots: { customRender: 'nilaiKd' } }
                  if (!this.kumer) {
                    return {
                      ...child,
                      key: child.dataIndex + kd.no,
                      dataIndex: child.dataIndex + kd.no,
                      align: 'center',
                      // ...scopedSlots,
                    }
                  } else {
                    return {
                      ...child,
                      key: child.dataIndex + kd.no + (idx + 1),
                      dataIndex: child.dataIndex + kd.no + (idx + 1),
                      align: 'center',
                      // ...scopedSlots,
                    }
                  }
                })
                if (!this.kumer) {
                  children.push({
                    title: 'Avg',
                    dataIndex: `${this.selectedReportText}_average${kd.no}`,
                    key: `${this.selectedReportText}_average${kd.no}`,
                    width: 80,
                    align: 'center',
                    scopedSlots: { customRender: 'kdAvg' },
                  })
                }
                if (this.kumer) {
                  return {
                    title: `Formatif ${kd.no}`,
                    key: `kd${kd.no}`,
                    dataIndex: `kd${kd.no}`,
                    align: 'center',
                    children,
                  }
                } else {
                  return {
                    ...kdCol,
                    title: `KD-${kd.no}`,
                    key: `kd${kd.no}`,
                    dataIndex: `kd${kd.no}`,
                    children,
                  }
                }
              })
              if (this.kumer) {
                colKds.push({
                  title: 'F Avg',
                  dataIndex: 'formatifAvg',
                  key: 'formatifAvg',
                  width: 80,
                  align: 'center',
                  scopedSlots: { customRender: 'nilai' },
                })
              }

              columns = columns.concat(colKds)
            } else {
              columns.push({
                title: 'KD',
                key: 'kd',
                dataIndex: 'kd',
                align: 'center',
                width: 400,
                customRender: (col, row, index) => {
                  if (index === 0) {
                    return {
                      children: (
                        <a-empty image={Empty.PRESENTED_IMAGE_SIMPLE}>
                          <div slot="description" class="value">
                            <span class="font-size-18" style="color: #0000009C">
                              No KD, ask this subject Teacher to plan the KD.
                            </span>
                          </div>
                        </a-empty>
                      ),
                      attrs: {
                        rowSpan: this.dataTable.length,
                      },
                    }
                  } else {
                    return {
                      attrs: {
                        rowSpan: 0,
                      },
                    }
                  }
                },
              })
            }
          }
        }

        if (this.reportType === 'final') {
          // push col PAS
          columns.push({
            title: 'PAS',
            dataIndex: `pas_${this.selectedReportText}`,
            key: `pas_${this.selectedReportText}`,
            width: 80,
            align: 'center',
            scopedSlots: { customRender: 'nilaiUjian' },
          })
          // push col nilai_pengetahuan atau nilai_keterampilan
          columns.push({
            title:
              this.selectedReportText === 'skill'
                ? 'NK'
                : this.selectedReportText === 'knowledge'
                ? 'NP'
                : 'N',
            dataIndex: `nilai_${this.tipeIndo}`,
            key: `nilai_${this.tipeIndo}`,
            width: 80,
            align: 'center',
            scopedSlots: { customRender: 'nilai' },
          })
        }
      }
      return columns
    },
  },
  methods: {
    handleReportType(e) {
      this.reportType = e.target.value
      this.fetchDataTableLedger()
    },
    handleExport() {
      const reportType = this.reportType
      this.loadingExport = true
      this.$store
        .dispatch('homeroom/FETCH_HOMEROOM_LEDGER', {
          idGuru: this.idWaliKelas,
          reportType,
          idMataPelajaran: 'All',
        })
        .then(({ data }) => {
          this.loadingExport = false
          if (reportType === 'mid') {
            LedgerMid(data, 'LEDGER MID')
          } else if (reportType === 'final') {
            LedgerFinal(data, 'LEDGER FINAL')
          }
        })
    },
    handleSelectMapel(value) {
      this.selectedMapel = value
      this.fetchDataTableLedger()
    },
    handleSelectReportText(value) {
      this.selectedReportText = value
      this.fetchDataTableLedger()
    },
    async fetchDataTableLedger() {
      try {
        const reportType = this.reportType
        const gradeReportType = this.selectedReportText
        const idMataPelajaran = this.selectedMapel
        this.loadingTable = true
        this.dataLedger = []
        const indexOfcolGradeMid = this.columnsGradeMid
          .map(function (e) {
            return e.key
          })
          .indexOf('rekapRaport')
        const indexOfcolGradeFinal = this.columnsGradeFinal
          .map(function (e) {
            return e.key
          })
          .indexOf('rekapRaport')
        this.columnsGradeMid[`${indexOfcolGradeMid}`].children = []
        this.columnsGradeFinal[`${indexOfcolGradeFinal}`].children = []
        const apiResult = await this.$store.dispatch(
          'homeroom/FETCH_HOMEROOM_LEDGER',
          {
            idGuru: this.idWaliKelas,
            reportType,
            idMataPelajaran,
            gradeReportType,
          },
        )
        if (idMataPelajaran === 'All') {
          const res = apiResult.data
          this.loadingTable = false
          this.dataTable = res.dataTable || []
          if (reportType === 'mid') {
            this.columnsGradeMid[`${indexOfcolGradeMid}`].children =
              res.dataColumn
          } else {
            this.columnsGradeFinal[`${indexOfcolGradeFinal}`].children =
              res.dataColumn
          }
        } else {
          this.loadingRefresh = false
          this.loadingTable = false
          const data = apiResult.data
          const listKd = apiResult.listKd
          const isKdMuridExist = apiResult.isKdMuridExist
          this.kumer = apiResult.kumer
          this.listKd = listKd
          const childrenCol =
            this.selectedReportText === 'knowledge'
              ? this.knowledgeChildrenKDCol
              : this.selectedReportText === 'skill'
              ? this.skillChildrenKDCol
              : []
          const dataIndexChildrenCol = childrenCol.map((col) => col.dataIndex)
          this.isKdMuridExist = isKdMuridExist
          this.dataTable = data.map((murid, i) => {
            const kd = {}
            const ptsPas = {}
            const dataReportMapel =
              murid.kelas_tahun_ajarans[0]?.report_mapels[0] || {}
            const dataKd = dataReportMapel.kd_murid_mapels || []
            if (!dataReportMapel.id) {
              ptsPas[`pts_${this.selectedReportText}`] = {
                data: null,
                type: `pts_${this.selectedReportText}`,
              }
              ptsPas[`pas_${this.selectedReportText}`] = {
                data: null,
                type: `pts_${this.selectedReportText}`,
              }
            } else {
              for (const key in dataReportMapel) {
                // if key is pts or pas in report type
                if (
                  [
                    `pts_${this.selectedReportText}`,
                    `pas_${this.selectedReportText}`,
                  ].includes(key)
                ) {
                  ptsPas[key] = {
                    data: dataReportMapel[key],
                    type: key,
                  }
                } else if (key === `nilai_${this.tipeIndo}`) {
                  ptsPas[key] = dataReportMapel[key]
                }
              }
            }

            this.listKd.forEach((kdMaster) => {
              dataIndexChildrenCol.forEach((childCol) => {
                kd[`${childCol}${kdMaster.no}`] = {
                  id: kdMaster.id,
                  type: childCol,
                  data: null,
                }
              })
            })
            let amountKdFilled = 0
            let totalAvgKd = 0
            dataKd.forEach((kdMurid) => {
              const kdMaster = this.listKd.find(
                (kdMaster) => kdMaster.id === kdMurid.id_kd_master,
              )
              if (kdMaster) {
                if (
                  kdMurid[`${this.selectedReportText}_average`] !== null &&
                  kdMurid[`${this.selectedReportText}_average`] !== undefined
                ) {
                  amountKdFilled++
                  totalAvgKd += kdMurid[`${this.selectedReportText}_average`]
                }
                for (const key in kdMurid) {
                  if (dataIndexChildrenCol.includes(key)) {
                    kd[`${key}${kdMaster.no}`] = {
                      id: kdMaster.id,
                      data: kdMurid[key],
                      type: key,
                    }
                  } else if (key === `${this.selectedReportText}_average`) {
                    kd[`${key}${kdMaster.no}`] = kdMurid[key]
                  }
                }
              }
            })
            let kdAvg
            if (amountKdFilled > 0) kdAvg = totalAvgKd / amountKdFilled
            return {
              kumer,
              ...murid,
              ...ptsPas,
              ...kd,
              kdAvg,
              key: murid.id,
              no: i + 1,
              id_kelas_tahun_ajaran: murid.kelas_tahun_ajarans[0].id,
            }
          })
          this.loadingTable = false
        }
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    changeCode(code, index) {
      let codeIndex = ''
      switch (code) {
        case 'written' + index:
          codeIndex = 'written'
          break
        case 'assignment' + index:
          codeIndex = 'assignment'
          break
        case 'oral' + index:
          codeIndex = 'oral'
          break
        case 'practical' + index:
          codeIndex = 'practical'
          break
        case 'project' + index:
          codeIndex = 'project'
          break
        case 'others' + index:
          codeIndex = 'others'
          break
        default:
          codeIndex = ''
          break
      }
      return codeIndex
    },
    color(nilai) {
      // console.log(nilai)
      if (nilai < 25) {
        return '#FF0000'
      } else if (nilai >= 25 && nilai < 50) {
        return '#FF8811'
      } else if (nilai >= 50 && nilai < 75) {
        return '#F9E900'
      } else if (nilai >= 75 && nilai < 100) {
        return '#7ABE28'
      } else {
        return '#41B883'
      }
    },
  },
}
</script>

<style></style>
